import React, { useEffect } from "react"
import styled from "styled-components"
import SecondaryButton from "../Button/SecondaryButton"

const ReviewGrid = styled.div`
  position: relative;
  z-index: 0;
  padding: 0;
`
const ExploreMore = styled.div`
  display: block;
  width: fit-content;
  margin: auto;
`

const ProductReviewWidget = () => {
  useEffect(() => {
    const script = document.createElement("script")
    script.src =
      "https://cdn.trustindex.io/loader.js?c3200882625e564b99567a60657"
    script.async = true
    script.defer = true
    document.getElementById("product-review-widget").appendChild(script)
  }, [])
  return (
    <ReviewGrid>
      <div id="product-review-widget"></div>
      <ExploreMore>
        <a
          href="https://admin.trustindex.io/api/googleWriteReview?place-id=ChIJofv_5Uk674gRgC1juuFZgf8"
          target="_blank"
          rel="noreferrer"
        >
          <SecondaryButton text="LEAVE US A REVIEW" />
        </a>
      </ExploreMore>
    </ReviewGrid>
  )
}

export default ProductReviewWidget
